// extracted by mini-css-extract-plugin
export var containerOnMobile = "minimum-viable-product-module--container-on-mobile--6885e";
export var description = "minimum-viable-product-module--description--2d3cd";
export var headerInnerWrapper = "minimum-viable-product-module--header-inner-wrapper--da5f6";
export var headerMainContent = "minimum-viable-product-module--header-main-content--21440";
export var landingPageBody = "minimum-viable-product-module--landing-page-body--bcc13";
export var landingPageHeader = "minimum-viable-product-module--landing-page-header--db3cc";
export var logoContainerHeader = "minimum-viable-product-module--logo-container-header--b381f";
export var mainTitle = "minimum-viable-product-module--main-title--22345";
export var outerWrapper = "minimum-viable-product-module--outer-wrapper--0465a";
export var readMore = "minimum-viable-product-module--read-more--909e7";