import React from 'react';

import featuredImage from './assets/FeaturedImage_mvp.jpg';
import mainImage from './assets/background-image.svg';
import whitePaperPdf from './assets/CodiLime_white_paper_MVP.pdf';

export const lpProps = {
  seoProps: {
    title: 'Minimum Viable Product for Startups: from Idea to Release',
    description:
      'Knowledge-packed ebook about how to start an MVP. Designed for startup entrepreneurs building their unique products for network industries and beyond!',
    featuredImage: featuredImage,
  },
  headerProps: {
    title: 'Minimum Viable Product: from idea to release',
    id: 'minimum-viable-product-for-startups',
    headerFigures: false,
    logoContainerDark: true,
  },
  formProps: {
    redirectUrl:
      'https://5206705.fs1.hubspotusercontent-na1.net/hubfs/5206705/White%20papers%20and%20case%20studies/CodiLime_marketing_white_paper_MVP.pdf',
    policyInfoType: 'type3',
    mainTitle: 'Grab your free checklist!',
    id: 'mvp-checklist-form',
    formId: '05f1bf7c-8d1f-4d62-b0b4-82c71c86923b',
    whitePaperPdf,
    thankYouMessage: (
      <>
        <h3>Thank you for submitting the form!</h3>
        <p>
          Your free ebook is already waiting for you. You can read it on the spot or download it for later.
          <br />
          <br />
          If you have any questions, let us know.
        </p>
      </>
    ),
  },
  learnAboutProps: {
    youWillGetToKnow: [
      'The most crucial stages in building a successful MVP',
      'The technology stack that will help you deliver and scale',
      'Inspirational case studies of practical implementations',
    ],
    summary: (
      <>
        Get a practical guide to help you get started with MVP. This ebook is crafted to help startup entrepreneurs
        validate their business ideas in a <strong>cost and time-effective manner</strong>.&nbsp;
      </>
    ),
    promise: <strong>With our ebook you will learn about:</strong>,
    cta: (
      <>
        Capitalize on CodiLime&apos;s 12 years of experience! <strong>Get your free ebook now -&gt;</strong>
      </>
    ),
    imageProps: {
      src: mainImage,
      alt: 'Download the MVP development guide',
    },
  },
};
