import React from 'react';

import { ListRender } from '@commons/list-render/ListRender';
import mainImage from '../assets/background-image.svg';

import * as styles from './second-part-on-mobile.module.scss';

export const SecondPartOnMobile = ({ listTitle, youWillGetToKnow }) => {
  return (
    <div className={styles.containerOnMobile} id="read-more">
      <p className={styles.listHeading}>{listTitle}</p>
      <ListRender data={youWillGetToKnow} withArrows lime />
      <div className={styles.imageContainer}>
        <img loading="lazy" src={mainImage} alt="Download the MVP development guide" />
      </div>
    </div>
  );
};
