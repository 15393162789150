import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { Header } from '@commons/header/Header';
import { LandingPageForm } from '@commons/landing-page-with-form/form/LandingPageForm';
import { LearnAbout } from '@commons/landing-page-with-form/learn-about/LearnAbout';
import { SecondPartOnMobile } from '@pages-impl/minimum-viable-product-for-startups';

import { lpProps } from '@pages-impl/minimum-viable-product-for-startups/payload';

import * as styles from './minimum-viable-product.module.scss';
import { Link } from '@commons/link/Link';

export default function Index() {
  const { layoutProps, seoProps, headerProps, learnAboutProps, formProps, customBackgroundImage } = lpProps;

  return (
    <Layout layout="landing" {...layoutProps} contactFormType={false}>
      <SEO {...seoProps} />
      <Header
        headerFigures={false}
        logoContainerDark={true}
        className={styles.landingPageHeader}
        logoContainerClassName={styles.logoContainerHeader}
        headerMainContentClassName={styles.headerMainContent}
        headerOuterWrapper={styles.outerWrapper}
        displayTopBanner={false}
        {...headerProps}
      />
      <div className={styles.landingPageBody}>
        <LearnAbout {...learnAboutProps} />
        <div className={styles.containerOnMobile}>
          <p className={styles.description}>
            {learnAboutProps.summary}
            <Link className={styles.readMore} href="#read-more">
              Read more &gt;
            </Link>
          </p>
        </div>
        <LandingPageForm {...formProps} />
        <SecondPartOnMobile listTitle={learnAboutProps.promise} youWillGetToKnow={learnAboutProps.youWillGetToKnow} />
        {customBackgroundImage}
      </div>
    </Layout>
  );
}
